.label-title {
  font-size: 1.75rem;
}

.label-download-app {
  font-size: 1.25rem;
}

.container-button-download {
  padding: 20px;
  background-color: white;
  border-radius: 12px;
}

.link {
  color: black;
  text-decoration: none;
  font-size: 16px;
}