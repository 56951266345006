.container-section-1 {
  background-color: #F5F5F5;
  height: 85vh;
}

.container-section-2 {
  background: linear-gradient(50deg, #ED1D40, #F5F5F5);
  height: 100vh;
}

.container-section-3 {
  background: linear-gradient(50deg, #FFDE5980, #FFFFFF);
  height: 100vh;
}

.container-section-4 {
  background: linear-gradient(50deg, #737373, #F5F5F5);
}

.container-image-step-2, .container-image-step-3 {
  width: 30%;
}

.container-image-step-4 {
  width: 30%;
}

@media (max-width: 768px) {
  .container-section-1, .container-section-2, .container-section-3 {
    height: auto;
  }
  .container-image-step-1 {
    height: 300px;
  }
}