.container-legal {
  width: 50%;
  height: 80px;
  background-color: #111b21;
  border-radius: 12px;
  cursor: pointer;
}

.legal-text {
  font-size: 22px;
  color: white;
}