.container-footer {
  background-color: #737373;
  height: auto;
}

.container-label-title-footer {
  display: flex;
  flex-direction: column;
  text-decoration: underline;
}

.image-footer {
  width: 100%;
  height: 60px;
}