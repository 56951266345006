body, .container {
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-body {
  background-color: #EBEBEB;
  height: 70%;
  display: flex;
  flex-direction: column;
}

.container-full-body {
  background-color: #EBEBEB;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container-row {
  display: flex;
  flex-direction: row;
}

.container-row-2 {
  display: flex;
  flex-direction: row;
}

.container-40 {
  width: 40%;
}

.container-60 {
  width: 60%;
}

.bold {
  font-size: 1.25rem;
  font-weight: 600;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.mt-10 {
  margin-top: 10px;
} 

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

span {
   font-size: 1.25rem;
}

.space-around {
  justify-content: space-around
}

.font-size-1 {
  font-size: 1rem;
}

.color-red {
  color: #ED1D40;
}

.gap-60 {
  gap: 60px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .container-40, .container-60 {
    width: 90%;
  }
  .container-row {
    flex-direction: column;
  }
  .ml-60 {
    margin-left: 10px;
  }
  .gap-60 {
    gap: 20px;
  }
}