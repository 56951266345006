.container-google-apple-image {
  cursor: pointer;
  height: 55px;
  margin: 20px;
}

@media (max-width: 768px) {
  .container-google-apple-image {
    width: 70%;
  }
}